import { handleActions, combineActions } from 'redux-actions'
import _ from 'lodash'

import { isPdfPage } from '@tabeeb/services/pageService'

import * as rawActions from '../actions'
import { AnnotationType } from '../../../Enums'

const defaultState = []

export default handleActions(
  {
    [combineActions(rawActions.setGalleryItems, rawActions.addGalleryItems)](state, action) {
      const pages = _.sortBy(action.payload, ['order', 'pdfURL', 'pdfPageNumber', 'timestampCreated', 'id'])
      return pages
    },
    [combineActions(rawActions.addGalleryItem, rawActions.updateGalleryItem)](state, action) {
      return _.sortBy(_addPage(state, action.payload), [
        'order',
        'pdfPageIndex',
        'pdfPageNumber',
        'timestampCreated',
        'id',
      ])
    },
    [combineActions(rawActions.deleteGalleryItem)](state, action) {
      return delPage(state, action.payload)
    },
    [combineActions(rawActions.renameGalleryItemSuccess, rawActions.updateGalleryItemName)](state, action) {
      return renamePage(state, action.payload)
    },
    [rawActions.setPageAsLoaded](state, action) {
      return setPageAsLoaded(state, action.payload)
    },
    [rawActions.setGalleryItemThumbnailIsLoaded](state, action) {
      return setThumbnailIsLoaded(state, action.payload)
    },
    [rawActions.updateGalleryItemThumbnail](state, action) {
      return updatePageThumbnail(state, action.payload)
    },
    [rawActions.addAnnotation](state, action) {
      return _addAnnotation(state, action.payload)
    },
    [rawActions.addAnnotations](state, action) {
      return _addAnnotations(state, action.payload)
    },
    [rawActions.updateAnnotation](state, action) {
      return _addAnnotation(state, action.payload)
    },
    [rawActions.deleteAnnotations](state, action) {
      return _delAnnotations(state, action.payload)
    },
    [rawActions.updateAiAnnotationsText](state, action) {
      return _updateAiAnnotationsText(state, action.payload)
    },
    [rawActions.updateOrAddAsset](state, action) {
      return updateOrAddAsset(state, action.payload)
    },
    [rawActions.resetGalleryState](state, action) {
      return defaultState
    },
  },
  defaultState
)

function delPage(state, { pageId }) {
  const deleteItem = state.find((page) => page.id === pageId)
  if (!deleteItem) {
    return state
  }

  let newState

  if (isPdfPage(deleteItem.contentType)) {
    newState = state.filter((page) => page.contentUrl !== deleteItem.contentUrl)
  } else {
    newState = state.filter((page) => page.id !== deleteItem.id)
  }

  for (const page of newState) {
    if (page.annotations) {
      const containsAnnotationsLinkedToDeletedPage = page.annotations.some(
        (annotation) => annotation.Type === AnnotationType.LinkedPage && annotation.LinkedPageId === pageId
      )
      if (containsAnnotationsLinkedToDeletedPage) {
        page.annotations = page.annotations.filter(
          (annotation) => !(annotation.Type === AnnotationType.LinkedPage && annotation.LinkedPageId === pageId)
        )
      }
    }
  }

  return newState
}

function _addPage(state, page) {
  const resultPages = [...state]
  const index = resultPages.findIndex((oldPage) => oldPage.id === page.id)

  if (index === -1) {
    resultPages.push(page)
  } else {
    resultPages[index] = { ...resultPages[index], ...page }
  }

  return resultPages
}

function renamePage(state, { pageId, name }) {
  return state.map((page) =>
    page.id === pageId
      ? {
          ...page,
          name,
          thumbnailCaption: name,
        }
      : page
  )
}

function setPageAsLoaded(state, { pageId }) {
  return state.map((page) => (page.id === pageId ? { ...page, isLoaded: true } : page))
}

function setThumbnailIsLoaded(state, { pageId }) {
  return state.map((page) => (page.id === pageId ? { ...page, isThumbnailLoaded: true } : page))
}

function updatePageThumbnail(state, { pageId, thumbnailUrl = null }) {
  const resultPages = [...state]
  const index = resultPages.findIndex((oldPage) => oldPage.id === pageId)

  if (index === -1) {
    return resultPages
  }

  resultPages[index] = { ...resultPages[index], timestampReceived: Date.now() }
  if (thumbnailUrl) {
    resultPages[index].thumbnailUrl = thumbnailUrl
  }

  return resultPages
}

function _addAnnotation(state, annotation) {
  const page = state.find((element) => element.id === annotation.PageId)

  if (!page) {
    return [...state]
  }

  const pageIndex = state.findIndex((element) => element.id === annotation.PageId)

  const index = page.annotations.findIndex((element) => element.Id === annotation.Id)
  let resultAnnotations
  if (index === -1) {
    resultAnnotations = [...page.annotations, annotation]
  } else {
    resultAnnotations = [...page.annotations]
    resultAnnotations[index] = annotation
  }
  page.annotations = resultAnnotations
  state[pageIndex] = page
  return [...state]
}

function _addAnnotations(state, annotations) {
  const pageId = annotations[0].PageId

  return state.map((page) => {
    if (page.id !== pageId) {
      return page
    }

    page.annotations = _.uniqBy([...page.annotations, ...annotations], (annotation) => annotation.Id)

    return page
  })
}

function _delAnnotations(state, annotations) {
  const pageId = annotations[0]?.PageId
  if (!pageId) {
    return state
  }

  const page = state.find((element) => element.id === pageId)
  const pageIndex = state.findIndex((element) => element.id === pageId)

  const ids = annotations.map((annotation) => annotation.Id)

  const resultAnnotations = page.annotations.filter(
    (annotation) => !ids.includes(annotation.Id) && !ids.includes(annotation.ParentAnnotationId)
  )

  page.annotations = resultAnnotations
  state[pageIndex] = page

  return [...state]
}

function _getUpdatedText(oldText, uniqueAiObjectName, propertiesText) {
  let text = (oldText || '').split(',')[0]

  if (uniqueAiObjectName) {
    text += `, Object: ${uniqueAiObjectName}`
  }

  if (propertiesText) {
    text += propertiesText
  }

  return text
}

function _updateText(annotations, uniqueAiObjectId, uniqueAiObjectName, propertiesText) {
  return annotations.map((annotation) =>
    annotation.Type === AnnotationType.AI && annotation.UniqueAIObjectId === uniqueAiObjectId
      ? { ...annotation, Text: _getUpdatedText(annotation.Text, uniqueAiObjectName, propertiesText) }
      : annotation
  )
}

function _updateAiAnnotationsText(state, { uniqueAiObjectId, uniqueAiObjectName, propertiesText }) {
  const resultPages = state.map((page) => ({
    ...page,
    annotations: _updateText(page.annotations, uniqueAiObjectId, uniqueAiObjectName, propertiesText),
  }))
  return resultPages
}

function updateOrAddAsset(state, updatedAsset) {
  const assetId = updatedAsset.Id
  const pageId = updatedAsset.PageId

  const assetPage = state.find((element) => element.id === pageId)
  if (!assetPage) {
    return state
  }

  const existingAsset = assetPage.assets.find((element) => element.Id === assetId)
  if (existingAsset) {
    return state.map((page) =>
      page.id === pageId
        ? {
            ...page,
            assets: page.assets.map((asset) => (asset.Id === assetId ? updatedAsset : asset)),
          }
        : page
    )
  }

  return state.map((page) => (page.id === pageId ? { ...page, assets: [...page.assets, updatedAsset] } : page))
}
